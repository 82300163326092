import Board from '../components/Board';
import { useUser } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Piece from '../components/Piece';
import IconButton from '../components/IconButton';
import { evaluateNotation, getPositionPiecesAfterMoves } from '../utils';
import { useState } from 'react';
import classNames from 'classnames';

function Game() {
  const { user } = useUser();
  const { gameIndex } = useParams();
  const [moveNumber, setMoveNumber] = useState(0);

  if (!user) {
    return null;
  }

  const game = evaluateNotation(user.publicMetadata.games[gameIndex]);
  const reversedBoard = game.playerNumber !== '1';

  const pieces = getPositionPiecesAfterMoves(game.moves.slice(0, game.moves.length - moveNumber * 2), game.initialNeutronPosition);

  const handleBackButton = () => {
    if (moveNumber < game.moves.length / 2) {
      setMoveNumber(moveNumber + 1);
    }
  };

  const handleForwardButton = () => {
    if (moveNumber > 0) {
      setMoveNumber(moveNumber - 1);
    }
  };

  return (
    <>
      <div className="game">
        <div className={classNames('player-details', reversedBoard && 'reversed')}>
          <div className="name">
            <span>{game.opponentName}</span>
            <span>{game.opponentElo}</span>
          </div>
        </div>

        <div className="board-container">
          <Board />

          <div className="pieces">
            {pieces.map((piece) => (
              <Piece
                key={piece.id}
                position={piece.position}
                player={piece.player}
                name={piece.name}
                id={piece.id}
                reversedBoard={reversedBoard}
              />))}
          </div>
        </div>
        <div className={classNames('player-details', reversedBoard && 'reversed')}>
          <div className="name">
            <span>Yo</span>
            <span>{game.myElo}</span>
          </div>
          <div>
            <IconButton icon="back" onClick={handleBackButton} disabled={moveNumber >= game.moves.length / 2} />
            <IconButton icon="forward" onClick={handleForwardButton} disabled={moveNumber <= 0} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Game;
