import { useAudio } from '../hooks/useAudio';

import './Button.scss';

function Button({ children, component, ...props }) {
  const Component = component || ((props) => <div {...props} />);
  const sound = useAudio('click');

  const handleClick = () => {
    sound.play();
  };

  return (
    <Component className="button" onMouseDown={handleClick} {...props}>{children}</Component>
  );
}

export default Button;
