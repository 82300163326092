import { Link } from "react-router-dom";
import ScreenContainer from "../components/ScreenContainer";
import Button from "../components/Button";
import { useGameContext } from "../contexts/gameContext";
import { useEffect } from "react";
import { SignedOut, SignedIn, SignOutButton, SignInButton } from "@clerk/clerk-react"
import UserInfo from "../components/UserInfo";
import Division from "../components/Division";

function Menu() {
  const { reset } = useGameContext();

  useEffect(() => {
    reset();
  }, []);

  return (
    <ScreenContainer>
      <Button component={Link} to={`/local`} >Juego Local</Button>
      <SignedIn>
        <Button component={Link} to={`/public`} >Juego Online</Button>
        <Button component={Link} to={`/private`} >Juego Privado</Button>
      </SignedIn>
      {/* <Button component={Link} to={`/settings`} >Configuración</Button> */}
      <Division />
      <SignedOut>
        <SignInButton />
      </SignedOut>
      <SignedIn>
        <UserInfo />
        <Button component={Link} to={`/games`} >Mis Partidas</Button>
        <SignOutButton />
      </SignedIn>
    </ScreenContainer>
  );
}

export default Menu;
