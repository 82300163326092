import { useUser } from '@clerk/clerk-react';

import './UserInfo.scss';
import { getEloForUser } from '../utils';

function UserInfo() {
  const { iseLoading, user } = useUser();

  if (iseLoading) {
    return '...';
  }

  const name = user.primaryEmailAddress?.emailAddress || user.fullName;
  
  return (
    <div className="user-info">
      <div className="name">
        {name}
      </div>
      <div className="elo">
        {getEloForUser(user)}
      </div>
    </div>
  );
}

export default UserInfo;
