import { useEffect, useState } from "react";

export const useTimer = (initialTime, inTurn, debug) => {
  const [turnStartedAt, setTurnStartedAt] = useState(new Date().valueOf());
  const [now, setNow] = useState(new Date().valueOf());

  useEffect(() => {
    if (inTurn && initialTime) {
      setTurnStartedAt(new Date().valueOf());
    } else {
      
    };
  }, [inTurn, initialTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().valueOf());
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  if (initialTime > 9000) {
    return initialTime;
  }

  const timeEllapsed = (now - turnStartedAt) / 1000;
  if (isNaN(initialTime)) {
    return 0;
  }
  if (!inTurn) {
    return initialTime;
  }
  if (initialTime - timeEllapsed < 0) {
    return 0;
  }
  return initialTime - timeEllapsed;
}

export const formatTime = (time) => {
  if (time > 9000) {
    return "";
  }
  const roundedTime = Math.round(time);
  const pad = (n) => n < 10 ? `0${n}` : n;

  const m = Math.floor(roundedTime / 60) - (0 * 60);
  const s = Math.floor(roundedTime - m * 60);

  return `${pad(m)}:${pad(s)}`;
};
