import { useState, useEffect } from 'react'

export const useBoardSize = () => {
  const [size, setSize] = useState(0);

  useEffect(() => {
    const updateSize = () => {
      const newSize = document.querySelector('.board-container')?.clientWidth;
      if (newSize) {
        setSize(newSize);
      }
    }

    window.addEventListener("resize", updateSize)
    updateSize()

    return () => window.removeEventListener("resize", updateSize)
  }, []);

  return size;
};
