import { useGameContext } from "../contexts/gameContext";
import { canBeSelected } from "../utils";
import { useAudio } from "../hooks/useAudio";

import './Pieces.scss';
import Piece from "./Piece";

function Pieces() {
  const { context, playerId, setSelected } = useGameContext();
  const selectSound = useAudio('select');

  const { reversedBoard, pieces, selected } = context;

  return (
    <div className="pieces">
      {pieces.map((piece) => (
        <Piece
          key={piece.id}
          position={piece.position}
          player={piece.player}
          name={piece.name}
          id={piece.id}
          selected={selected}
          onClick={() => {
            const pieceToSelect = canBeSelected(context, playerId, piece.id) ? piece.id : null;
            setSelected(pieceToSelect);
            if (pieceToSelect) {
              selectSound.play();
            }
          }}
          reversedBoard={reversedBoard}
        />))}
    </div>
  );
}

export default Pieces;
