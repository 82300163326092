import { createContext, useContext } from 'react';
import { useUser } from '@clerk/clerk-react';
import { getInitialPositionPieces, getNeutronInitialPosition, getPlayerNotInTurn, getWinner, getWinninReason } from '../utils';

const GameContext = createContext({
  context: {},
  setContext: () => {},
});

export const useGameContext = () => {
  const { user } = useUser();
  const { context, setContext } = useContext(GameContext);
  window.context = context;
  return {
    context,
    setSelected: (id) => setContext({
      ...context,
      selected: id,
    }),
    setGameType: (type, time) => setContext({
      ...context,
      gameType: type,
      gameTime: time,
    }),
    resetSameGameType: () => {
      const { gameType, gameTime } = context;
      setContext({
        ...getInitialValue(),
        gameType,
        gameTime,
      });
    },
    reset: () => setContext({
      ...getInitialValue(),
    }),
    moveTo: (position) => {
      const updatedGame = {
        ...context,
        pieces: context.pieces.map(p => {
          if (p.id === context.selected) {
            return { ...p, position };
          }
          return p;
        }),
        selected: null,
        playerInTurn: context.step === 2 ? getPlayerNotInTurn(context) : context.playerInTurn,
        step: context.step === 1 ? 2 : 1,
      };
      setContext({
        ...updatedGame,
        winner: getWinner(updatedGame),
        winningReason: getWinninReason(updatedGame),
      });
    },
    updateContext: (game) => setContext({
      ...context,
      gameId: game.id,
      player1: game.player1,
      player2: game.player2,
      pieces: game.pieces,
      playerInTurn: game.playerInTurn,
      step: game.step,
      winner: game.winner,
      winningReason: game.winningReason,
      reversedBoard: game.player2?.id === user?.id,
      gameTime: game.gameTime,
      initialNeutronPosition: game.initialNeutronPosition,
    }),
    playerId: user?.id,
  };
};

export const getInitialValue = () => {
  const initialNeutronPosition = getNeutronInitialPosition();

  return ({
    pieces: getInitialPositionPieces(initialNeutronPosition),
    playerInTurn: 1,
    step: 1,
    player1: null,
    player2: null,
  
    reversedBoard: false,
    selected: null,
    gameId: null,
  });
};

export default GameContext;
