import { useUser } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";

import ScreenContainer from "../components/ScreenContainer";
import { evaluateNotation, getEloForUser } from "../utils";

function Games() {
  const { iseLoading, user } = useUser();
  const navigate = useNavigate();

  if (iseLoading) {
    return '...';
  }

  const games = (user?.publicMetadata?.games || []).map(evaluateNotation);

  return (
    <ScreenContainer>
      <div className="games">
        <Chart
          chartType="LineChart"
          width="100%"
          height="40vh"
          data={[
            ["Match", "Elo"],
            ...games.reduce((acc, g, i) => {
              acc.push([i, Number(g.myElo)]);
              return acc;
            }, []),
            [games.length, getEloForUser(user)],
          ]}
          options={{
            legend: { position: "none" },
            series: {
              0: { color: '#FFFFFF' },
            },
            hAxis: {
              textPosition: 'none',
              gridlines: { color: '#1E4D6B' },
            },
            vAxis: {
              textStyle: { color: '#FFF' },
              gridlines: { color: '#1E4D6B' },
            },
            backgroundColor: {
              fill: '#123863',
            },
            chartArea: {
              width: '75%',
              height: '85%',
            },
          }}
        />
        <table>
          <tbody>
            {games.map((g, index) => (
              <tr key={index} onClick={() => navigate('/games/' + index)}>
                <td className="my-name">Yo</td>
                <td className="my-elo">{g.myElo}</td>
                <td className="result">
                  <div className={"result-" + g.result}></div>
                </td>
                <td className="opponent-elo">{g.opponentElo}</td>
                <td className="opponent-name">{g.opponentName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ScreenContainer>
  );
}

export default Games;
