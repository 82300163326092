import { useAudio } from '../hooks/useAudio';

import './Input.scss';

function Input({ placeholder, value, onChange, ...props }) {
  const sound = useAudio('click');

  const handleChange = (e) => {
    sound.play();
    onChange(e.target.value);
  };

  return (
    <input className="input" placeholder={placeholder} value={value} onChange={handleChange} {...props} />
  );
}

export default Input;
