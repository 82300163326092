import useWebSocket from 'react-use-websocket';
import { useGameContext } from './contexts/gameContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useAuth, useUser } from '@clerk/clerk-react';
import { getEloForUser } from './utils';

export const useConnection = () => {
  const { user } = useUser();
  const { context, updateContext } = useGameContext();
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(process.env.REACT_APP_NEUTRON_WEBSOCKET_URL + '?playerId=' + user?.id, {
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    share: false,
    filter: () => true,
    retryOnError: true,
    shouldReconnect: () => true
  });

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.game) {
      updateContext(lastJsonMessage.game);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  const obtainUpdate = useCallback(() => {
    sendJsonMessage({
      type: 'obtain-update',
      metadata: {
        gameId: context.gameId,
      },
    });
  }, [sendJsonMessage, context.gameId]);

  return {
    startNewOnlineGame: (type, gameTime) => {
      sendJsonMessage({
        type: 'start-online',
        metadata: {
          gameTime,
          gameType: type,
          elo: getEloForUser(user),
        },
      });
    },
    joinPrivateGame: (code) => {
      sendJsonMessage({
        type: 'join-private',
        metadata: {
          code,
          elo: getEloForUser(user),
        },
      });
    },
    sendMove: (from, to) => {
      sendJsonMessage({
        type: 'move',
        metadata: {
          gameId: context.gameId,
          from,
          to,
        },
      });
    },
    obtainUpdate,
    context,
  };
};

export const useApi = () => {
  const { getToken } = useAuth();
  
  return useMemo(() => {
    return {
      getUserInfo: async () => {
        const token = await getToken();
        const result = await fetch(process.env.REACT_APP_NEUTRON_SERVER_URL + '/userinfo', {
          headers: { Authorization: `Bearer ${token}` }
        });
        const info = await result.json();
        return info;
      }
    };
  }, [getToken]);
}