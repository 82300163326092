import classNames from 'classnames';
import { useAudio } from '../hooks/useAudio';

import './IconButton.scss';
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

const map = {
  back: AiFillCaretLeft,
  forward: AiFillCaretRight,
};

function IconButton({ icon, component, disabled, onclick, ...props }) {
  const Component = component || ((props) => <div {...props} />);
  const sound = useAudio('click');

  const handleMouseDown = () => {
    if (!disabled) {
      sound.play();
    }
  };

  const handleClick = () => {
    if (!disabled) {
      onclick();
    }
  };

  const Icon = map[icon];

  return (
    <Component className={classNames("icon-button", disabled && 'disabled')} onMouseDown={handleMouseDown} onClick={handleClick} {...props}><Icon /></Component>
  );
}

export default IconButton;
