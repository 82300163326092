const { BOARD_SIZE } = require("./constants");

const findPieceById = (context, pieceId) => {
  return context.pieces.find(p => p.id === pieceId);
};

const isMyTurn = (context, playerId) => {
  return context['player' + context.playerInTurn]?.id === playerId;
};

const hasGameStarted = (context) => {
  return context.player1 && context.player2;
};

const hasGameFinished = (context) => {
  return !!context.winner;
};

const canBeSelected = (context, playerId, pieceId) => {
  const piece = findPieceById(context, pieceId);

  if (context.gameType === 'public' && !isMyTurn(context, playerId)) {
    return false;
  }

  if (context.winner) {
    return false;
  }

  if (context.step === 1) {
    return piece.player === 0;
  }

  return context.playerInTurn === piece.player;
};

const isCellOccuped = (context, position) => {
  if (position === 0) return true;
  return context.pieces.some(p => p.position === position);
};

const getMoves = (context, position) => {
  const moves = [];
  const coords = buildCoordsFromPosition(position);

  // right
  const rightTo = BOARD_SIZE - coords[0];
  const rightToPosition = getMoveInDirection(context, coords, rightTo, 1, 0);
  if (rightToPosition) {
    moves.push(rightToPosition);
  }

  // left
  const leftTo = coords[0];
  const leftToPosition = getMoveInDirection(context, coords, leftTo, -1, 0);
  if (leftToPosition) {
    moves.push(leftToPosition);
  }

  // up
  const upTo = coords[1];
  const upToPosition = getMoveInDirection(context, coords, upTo, 0, -1);
  if (upToPosition) {
    moves.push(upToPosition);
  }

  const downTo = BOARD_SIZE - coords[1];
  const downToPosition = getMoveInDirection(context, coords, downTo, 0, 1);
  if (downToPosition) {
    moves.push(downToPosition);
  }

  const upRightTo = Math.min(BOARD_SIZE - coords[0], coords[1]);
  const upRightToPosition = getMoveInDirection(context, coords, upRightTo, 1, -1);
  if (upRightToPosition) {
    moves.push(upRightToPosition);
  }

  const upLeftTo = Math.min(coords[0], coords[1]);
  const upLeftToPosition = getMoveInDirection(context, coords, upLeftTo, -1, -1);
  if (upLeftToPosition) {
    moves.push(upLeftToPosition);
  }

  const downRightTo = Math.min(BOARD_SIZE - coords[0], BOARD_SIZE - coords[1]);
  const downRightToPosition = getMoveInDirection(context, coords, downRightTo, 1, 1);
  if (downRightToPosition) {
    moves.push(downRightToPosition);
  }

  const downLeftTo = Math.min(coords[0], BOARD_SIZE - coords[1]);
  const downLeftToPosition = getMoveInDirection(context, coords, downLeftTo, -1, 1);
  if (downLeftToPosition) {
    moves.push(downLeftToPosition);
  }

  return moves;
};

const canBeDestination = (context, position) => {
  const pieceId = context.selected;

  if (!pieceId) return false;

  const piece = findPieceById(context, pieceId);

  if (isCellOccuped(context, position)) {
    return false;
  }

  const moves = getMoves(context, piece.position);

  return moves.includes(position);
};

const getMe = (context) => {
  return context.reversedBoard ? context.player2 : context.player1;
}

const getOpponent = (context) => {
  return context.reversedBoard ? context.player1 : context.player2;
}

const getPlayerNotInTurn = (context) => {
  return context.playerInTurn === 1 ? 2 : 1;
};

const buildPositionFromCoord = (i, j) => {
  if (i < 0 || i > BOARD_SIZE - 1 || j < 0 || j > BOARD_SIZE - 1) {
    return 0;
  }
  return j * BOARD_SIZE + i + 1;
};

const buildCoordsFromPosition = (position) => {
  return [
    (position % BOARD_SIZE === 0 ? BOARD_SIZE : position % BOARD_SIZE) - 1,
    Math.ceil(position / BOARD_SIZE) - 1,
  ];
};

const getMoveInDirection = (context, coords, amount, directionX, directionY) => {
  const to = amount;
  let toPosition = null;
  for (let i = 1; i <= to; i++) {
    const cell = buildPositionFromCoord(coords[0] + i * directionX, coords[1] + i * directionY);
    if (!isCellOccuped(context, cell)) {
      toPosition = cell;
    } else {
      break;
    }
  }
  if (toPosition) {
    return toPosition;
  }
  return null;
}

const getWinner = (game) => {
  if (game.winner) {
    return game.winner;
  }
  const neutronPosition = game.pieces.find(p => p.player === 0).position;
  if (neutronPosition >= BOARD_SIZE * BOARD_SIZE - BOARD_SIZE + 1) {
    return 1;
  }
  if (neutronPosition <= BOARD_SIZE) {
    return 2;
  }
  return null;
}

const getWinninReason = (game) => {
  if (game.winningReason) {
    return game.winningReason;
  }
  if (getWinner(game)) {
    return 'piece';
  }
  return null;
}

const isWinnerOrLooser = (game) => {
  if (game.winner === 1) {
    if (game.reversedBoard) {
      return ['l', game.winningReason];
    } else {
      return ['w', game.winningReason];
    }
  } else if (game.winner === 2) {
    if (game.reversedBoard) {
      return ['w', game.winningReason];
    } else {
      return ['l', game.winningReason];
    }
  }
  return [null, null];
};

const getWinningMessage = (game) => {
  let message = '';
  let reason = '';
  const [status, winningReason] = isWinnerOrLooser(game);

  if (status === 'l') {
    message = 'Perdiste';
    if (winningReason === 'time') {
      reason = 'Se te ha acabado el tiempo';
    } else if (winningReason === 'disconnection') {
      reason = 'Abandonaste la partida';
    } else if (winningReason === 'no-moves') {
      reason = 'Te quedaste sin movimientos';
    } else {
      reason = 'Tu oponente obtuvo la victoria';
    }
  } else if (status === 'w') {
    message = 'Ganaste';
    if (winningReason === 'time') {
      reason = 'Tu oponente se ha quedado sin tiempo';
    } else if (winningReason === 'disconnection') {
      reason = 'Tu oponente abandonó la partida';
    } else if (winningReason === 'no-moves') {
      reason = 'Tu oponente se quedó sin movimientos';
    } else {
      reason = 'Obtuviste la victoria';
    }
  }

  return [message, reason];
};

const evaluateNotation = (notation) => {
  const [opponentName, myElo, opponentElo, playerNumber, result, initialNeutronPosition, moves] = notation.split('-');

  return {
    opponentName,
    myElo,
    opponentElo,
    playerNumber,
    result,
    initialNeutronPosition,
    moves: moves.match(/.{1,2}/g),
  };
};

const getPositionPiecesAfterMoves = (moves, initialNeutronPosition) => {
  const pieces = getInitialPositionPieces(initialNeutronPosition);
  let piece = null;
  moves.forEach((move, index) => {
    if (index % 2 === 0) {
      piece = pieces.find(p => p.position === Number(move));
    } else {
      piece.position = Number(move);
    }
  });
  return pieces;
};

const getNeutronInitialPosition = () => {
  return Math.floor(Math.random() * (BOARD_SIZE * BOARD_SIZE - 2 * BOARD_SIZE)) + BOARD_SIZE + 1;
};

const getInitialPositionPieces = (neutronPosition) => {
  const pieces = [];

  for (let i = 0; i < BOARD_SIZE; i++) {
    pieces.push({ id: i + 1, name: 'P' + (i + 1), position: i + 1, player: 2 });
  }
  for (let i = 0; i < BOARD_SIZE; i++) {
    pieces.push({ id: BOARD_SIZE + i + 1, name: 'E' + (i + 1), position: BOARD_SIZE * BOARD_SIZE - BOARD_SIZE + i + 1, player: 1 });
  }

  pieces.push({ id: pieces.length + 1, name: 'N', position: Number(neutronPosition), player: 0 });

  return pieces;;
};

const renderBoardInAscii = ({ pieces }) => {
  const boardSize = BOARD_SIZE;
  const board = Array(boardSize * boardSize).fill('-');

  // Fill board with pieces
  for (const piece of pieces) {
    board[piece.position - 1] = piece.name.substr(0, 1);
  }

  // Render the board
  let boardString = '';
  for (let i = 0; i < boardSize; i++) {
    for (let j = 0; j < boardSize; j++) {
      boardString += board[i * boardSize + j] + ' ';
    }
    boardString += '\n';
  }

  console.log(boardString);
  return boardString;
};

const getNullPlayerNumber = (game) => game.player1 ? 2 : 1;

const getAssignedPlayerNumber = (game) => game.player1 ? 1 : 2;

const getEloForUser = (user) => {
  const value = user?.publicMetadata?.elo;
  if (!value || value < 0) {
    return 0;
  }
  return value;
}

const spells = {
  1: {
    name: 'Saltar movimiento de Neutrón',
    when: 0,
    cold: 1,
  },
  2: {
    name: 'Saltar movimiento de Pieza',
    when: 1,
    cold: 1,
  },
  3: {
    name: 'Saltar turno',
    when: 0,
    cold: 1,
  },
  4: {
    name: 'Deshacer movimiento de Neutrón',
    when: 0,
    cold: 1,
  },
  5: {
    name: 'Bloquear movimiento para Neutrón de oponente',
    when: 1,
    cold: 2,
  },
  6: {
    name: 'Bloquear movimiento para Pieza de oponente',
    when: 1,
    cold: 2,
  },
  7: {
    name: 'Freezar pieza de oponente',
    when: 1,
    cold: 2,
    select: ({ piece, opponentNumber }) => piece && piece.name !== 'N' && piece.player === opponentNumber,
  },
  8: {
    name: 'Agregar pieza neutra',
    when: 1,
    cold: 1,
    select: ({ piece }) => !piece,
  },
  9: {
    name: 'Cancelar hechizo de oponente',
    when: 0,
    cold: 2,
  },
};

module.exports = {
  findPieceById,
  isMyTurn,
  hasGameStarted,
  hasGameFinished,
  canBeSelected,
  isCellOccuped,
  getMoves,
  canBeDestination,
  getMe,
  getOpponent,
  getPlayerNotInTurn,
  buildPositionFromCoord,
  buildCoordsFromPosition,
  getWinner,
  getWinninReason,
  isWinnerOrLooser,
  getWinningMessage,
  evaluateNotation,
  getPositionPiecesAfterMoves,
  getNeutronInitialPosition,
  getInitialPositionPieces,
  renderBoardInAscii,
  getNullPlayerNumber,
  getAssignedPlayerNumber,
  getEloForUser,
};