import { useEffect, useRef } from "react";
import winSound from '../audios/win.wav';
import loseSound from '../audios/lose.wav';
import moveSound from '../audios/move.mp3';
import selectSound from '../audios/select.mp3';
import clickSound from '../audios/click.mp3';

const map = {
  win: winSound,
  lose: loseSound,
  move: moveSound,
  select: selectSound,
  click: clickSound,
};

export const useAudio = (name) => {
  const ref = useRef(new Audio());

  useEffect(() => {
    ref.current.src = map[name];
    ref.current.preload = "auto";
  }, [name]);

  return ref.current;
};
