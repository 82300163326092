import { useEffect, useState } from 'react';
import Board from '../components/Board';
import Button from '../components/Button';
import Message from '../components/Message';
import Pieces from '../components/Pieces';
import { useGameContext } from '../contexts/gameContext';
import { useConnection } from '../api';
import { formatTime, useTimer } from '../time';
import { getMe, getOpponent, isMyTurn, hasGameStarted, hasGameFinished, getWinningMessage } from '../utils';
import ScreenContainer from '../components/ScreenContainer';
import { useNavigate } from 'react-router-dom';
import Input from '../components/Input';
import Division from '../components/Division';
import { useUser } from '@clerk/clerk-react';
import classNames from 'classnames';

function GameOnline({ type }) {
  const { user } = useUser();
  const { setGameType, context, reset, resetSameGameType, playerId } = useGameContext();

  const navigate = useNavigate();
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [enteredCode, setEnteredCode] = useState("");

  const started = hasGameStarted(context);
  const myTime = useTimer(getMe(context)?.time, !context.winner && started && isMyTurn(context, playerId), true);
  const opponentTime = useTimer(getOpponent(context)?.time, !context.winner && started && !isMyTurn(context, playerId));
  const finished = hasGameFinished(context);
  const { startNewOnlineGame, sendMove, obtainUpdate, joinPrivateGame } = useConnection();

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (user && context.winner) {
      console.log('reloading');
      user.reload();
    }
  }, [user, context.winner]);

  useEffect(() => {
    if (started && !finished && (myTime <= 0 || opponentTime <= 0)) {
      obtainUpdate();
    }
  }, [started, finished, myTime, opponentTime, obtainUpdate]);

  const handleOnMove = (position) => {
    sendMove(context.pieces.find(p => p.id === context.selected).position, position);
  };

  const handleSetGameTime = (gameTime) => {
    const gameType = type === 'private' ? 'private' : 'public';
    setGameType(gameType, gameTime);

    startNewOnlineGame(gameType, gameTime);
  };

  if (!playerId) {
    return null;
  }

  if ((!context.player1 || !context.player2) && showEnterCode) {
    return (
      <ScreenContainer>
        <Input value={enteredCode} onChange={(v) => setEnteredCode(v)} placeholder="ABC123" />
        <Button onClick={() => joinPrivateGame(enteredCode)}>Comenzar</Button>
      </ScreenContainer>);
  }

  if (!context.gameTime) {
    return (
      <ScreenContainer>
        <Button onClick={() => handleSetGameTime(30)}>30 segundos</Button>
        <Button onClick={() => handleSetGameTime(60)}>1 minuto</Button>
        <Button onClick={() => handleSetGameTime(180)}>3 minutos</Button>
        <Button onClick={() => handleSetGameTime(600)}>10 minutos</Button>
        <Division />
        <Button onClick={() => setShowEnterCode(true)}>Unirse a un Juego</Button>
      </ScreenContainer>);
  }

  if (!context.gameId) {
    return null;
  }

  if (!context.player2 || !context.player1) {
    if (type === 'private') {
      return (
        <Message>
          Envía éste código a tu amigo: <br />
          {context.gameId}<br />
          Esperando...
        </Message>
      );
    }
    return <Message>Esperando oponente...</Message>;
  }

  const [message, reason] = getWinningMessage(context);

  return (
    <>
      <div className="game">
        <div className={classNames('player-details', context.reversedBoard && 'reversed')}>
          <div className="name">
            <span>Oponente</span>
            <span>{context.reversedBoard ? context.player1?.elo : context.player2?.elo}</span>
          </div>
          <div>{formatTime(opponentTime)}</div>
        </div>

        <div className="board-container">
          <Board onMove={handleOnMove} />
          <Pieces />
        </div>
        <div className={classNames('player-details', context.reversedBoard && 'reversed')}>
          <div className="name">
            <span>Yo</span>
            <span>{context.reversedBoard ? context.player2?.elo : context.player1?.elo}</span>
          </div>
          <div>{formatTime(myTime)}</div>
        </div>
      </div>
      {context.winner && (
        <Message>
          <div>{message}</div>
          <div>{reason}</div>
          <div className="button-container">
            <Button onClick={() => {
              resetSameGameType();
              startNewOnlineGame(context.gameType, context.gameTime);
            }}>Jugar de nuevo</Button>
            <Button onClick={() => navigate(-1)}>Volver</Button>
          </div>
        </Message>
      )}
    </>
  );
}

export default GameOnline;
