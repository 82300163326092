import { useEffect, useState } from "react";
import "./Message.scss";

function Message({ children }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1500);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="message">
      <div className="message-inner">
        {children}
      </div>
    </div>
  );
}

export default Message;
