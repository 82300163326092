import wood from '../images/board.png';
import classnames from 'classnames';
import { BOARD_SIZE } from '../constants';
import { useGameContext } from '../contexts/gameContext';
import { buildPositionFromCoord, canBeDestination, isWinnerOrLooser } from '../utils';
import { useEffect } from 'react';
import { useAudio } from '../hooks/useAudio';

import './Board.scss';

function Board({ onMove }) {
  const { context, setSelected, moveTo } = useGameContext();
  const [status] = isWinnerOrLooser(context);

  const winSound = useAudio('win');
  const loseSound = useAudio('lose');
  const moveSound = useAudio('move');

  useEffect(() => {
    if (status === 'w') {
      winSound.play();
    } else if (status === 'l') {
      if (context.gameType === 'local') {
        winSound.play();
      } else {
        loseSound.play();
      }
    }
  }, [status, winSound, loseSound, context.gameType]);

  return (
    <div className={classnames('board', context.reversedBoard && 'reverse')} style={{ backgroundImage: 'url(' + wood + ')' }}>
      {new Array(BOARD_SIZE).fill(null).map((_, j) => (
        <div key={j} className="row">
          {new Array(BOARD_SIZE).fill(null).map((_, i) => {
            const position = buildPositionFromCoord(i, j);
            const isDestination = canBeDestination(context, position);
            return (
              <div
                key={i}
                className={classnames('cell', isDestination && 'destination')}
                onClick={() => {
                  if (isDestination) {
                    moveTo(position);
                    moveSound.play();
                    onMove && onMove(position);
                  } else {
                    setSelected(null);
                  }
                }}>
  
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default Board;
