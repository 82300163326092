import { useEffect, useState } from 'react';
import Board from '../components/Board';
import Pieces from '../components/Pieces';
import { useGameContext } from '../contexts/gameContext';
import Message from '../components/Message';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

function GameLocal() {
  const { setGameType, context, reset } = useGameContext();
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
    setGameType('local');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <div className="game">
        <div>

        </div>

        <div className="board-container">
          <Board />
          <Pieces />
        </div>
        <div>

        </div>
      </div>
      {context.winner && (
        <Message>
          <div>Jugador {context.winner === 1 ? 'rojo' : 'azul'} gana</div>
          <div className="button-container">
            <Button onClick={() => reset()}>Jugar de nuevo</Button>
            <Button onClick={() => navigate(-1)}>Volver</Button>
          </div>
        </Message>
      )}
    </>
  );
}

export default GameLocal;
