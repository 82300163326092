import { BOARD_SIZE } from "../constants";
import classnames from 'classnames';
import { useBoardSize } from "../hooks/useBoardSize";

import './Piece.scss';

function Piece({ position, player, name, id, selected, onClick, reversedBoard }) {
  const totalSize = useBoardSize();
  const size = totalSize / BOARD_SIZE;
  const padding = size * 0.1;

  const top = (Math.floor((position - 1) / BOARD_SIZE)) * size;
  const left = ((position - 1) % BOARD_SIZE) * size;

  return (
    <div
      className={classnames('piece', 'p' + player, 'p' + name.toLowerCase(), id === selected && 'selected')}
      key={id}
      onClick={onClick}
      style={{
        width: size - padding * 2,
        height: size - padding * 2,
        left: reversedBoard ? totalSize - left + padding - size : left + padding,
        top: reversedBoard ? totalSize - top + padding - size : top + padding,
      }}>
    </div>
  );
}

export default Piece;
