import bg from './images/bg.png';
import './App.scss';
import Menu from './screens/Menu';
import GameContext, { getInitialValue } from './contexts/gameContext';
import { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ClerkProvider } from '@clerk/clerk-react';
import GameLocal from './screens/GameLocal';
import GameOnline from './screens/GameOnline';
import Games from './screens/Games';
import Game from './screens/Game';

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Menu />,
  },
  {
    path: "/local",
    element: <GameLocal />,
  },
  {
    path: "/public",
    element: <GameOnline type="public" />,
  },
  {
    path: "/private",
    element: <GameOnline type="private" />,
  },
  {
    path: "/games",
    element: <Games />,
  },
  {
    path: "/games/:gameIndex",
    element: <Game />,
  },
  {
    path: "/settings",
    element: <div />,
  },
]);

function App() {
  const [context, setContext] = useState(getInitialValue());

  return (
    <div className="app" style={{ backgroundImage: 'url(' + bg + ')' }}>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <GameContext.Provider value={{ context, setContext }}>
          <RouterProvider router={router} />
        </GameContext.Provider>
      </ClerkProvider>
    </div>
  );
}

export default App;
